<template>
  <div class="router-tags row no-gutters align-items-center">
    <div class="col-auto">
      <el-button
        circle
        :icon="ctrl.sidebar.isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        @click="ctrl.sidebar.toggleSidebar()"
      />
    </div>
    <div class="col swiper-tags">
      <swiper ref="swiper" class="swiper" :options="swiperOptions">
        <template v-for="(tag, i) in ctrl.routerTab.tabs">
          <swiper-slide :key="i">
            <el-popover
              placement="bottom"
              width="200"
              trigger="manual"
              v-model="popMap[i]"
            >
              <div
                slot="reference"
                class="disable-select"
                @contextmenu.prevent="showContext($event, tag)"
              >
                <el-tag
                  class="disable-select"
                  :class="{current: tag.selected}"
                  :closable="!ctrl.routerTab.isRootTab(tag)"
                  @click="ctrl.routerTab.selectTab(tag)"
                  @close="ctrl.routerTab.closeTab(tag)"
                >
                  {{ cleanTabName(tag.name) || '未命名' }}
                </el-tag>
              </div>
            </el-popover>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <div class="col-auto">
      <el-button icon="el-icon-refresh-right" v-if="hasRefresh" circle @click="currentPage.refresh($route)"></el-button>
    </div>
  </div>
</template>

<script lang="js">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {$layout} from '../layout.service';

export default @Component({
  components: {
    Swiper,
    SwiperSlide,
  },
})
class RouterTab extends Vue {
  ctrl = $layout;
  popMap = {};
  currentPage = {};
  hasRefresh = false;

  swiperOptions = {
    loop: false,
    slidesPerView: 'auto',
    spaceBetween: 16,
  };

  mounted() {
    this.buildCurrentPage();
    this.buildHasRefresh();
  }

  cleanTabName(name) {
    if (name) {
      name = name.split(' ')[0];
      name = name.split('@')[0];
      return name;
    }
    return '';
  }

  showContext(event, tab) {
    if (tab.name === '首页') return;
    this.$contextmenu({
      items: [
        {
          label: "关闭",
          onClick: () => {
            this.ctrl.routerTab.closeTab(tab);
          }
        },
        {
          label: "关闭其他标签",
          onClick: () => {
            this.ctrl.routerTab.closeOthers(tab);
          }
        },
      ],
      event,
      zIndex: 900,
    })
  }

  buildCurrentPage() {
    if (!this.$route.matched || this.$route.matched.length === 0) {
      this.currentPage = null;
    } else {
      this.currentPage = this.$route.matched[this.$route.matched.length - 1].instances.default;
    }
  }

  buildHasRefresh() {
    const page = this.currentPage;
    this.hasRefresh = !!page && !!page.refresh;
  }

  @Watch('$route')
  routeChange(route) {
    setTimeout(() => {
      this.buildCurrentPage();
      this.buildHasRefresh();
      this.ctrl.routerTab.tabs.forEach((tab, index) => {
        if (tab.selected) {
          this.$refs.swiper.$swiper.slideTo(index);
        }
      });
    }, 200);
  }
}
</script>

<style lang="scss">
.router-tags {
  height: 40px;

  .el-button {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    color: teal;
  }

  .swiper-tags {
    height: 36px;
    margin: 0 16px;

    .swiper-container {
      position: absolute;
      width: 100%;
      height: 100%;

      .swiper-slide {
        width: auto;
      }
    }
  }

  .el-tag {
    background-color: #fff;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    height: 34px;
    padding-right: 18px;
    padding-left: 18px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &.current {
      background-color: teal;
      color: #fff;

      i {
        color: #fff;

        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
}
</style>
