module.exports = {
  // 开发环境
  dev: {
    buyer_pc: 'http://127.0.0.1:3000',
    buyer_wap: 'http://127.0.0.1:3001',
    seller: 'http://127.0.0.1:3002',
    admin: 'http://127.0.0.1:3003',
  },
  // 生产环境
  pro: {
    buyer_pc: 'http://127.0.0.1:3000',
    buyer_wap: 'http://127.0.0.1:3001',
    seller: 'https://tseller.zhangyujiaqi.com/',
    admin: 'http://127.0.0.1:3003',
  },
};
