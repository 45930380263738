<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script lang="js">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {$layout} from '@/libs/layout/layout.service';

@Component
class App extends Vue {
  @Watch('$route')
  onRouteChanged(newRoute) {
    $layout.routerTab.appendTab(newRoute);
    $layout.routerTab.refresh(newRoute);

    $layout.navbar.onLogoClick.subscribe(() => {
      console.log('layout');
    });
  }

  mounted() {
    if (this.$store.state.user.user) {
      this.$store.dispatch('getShopInfoAction');
    }
  }
}

export default App;
</script>

<style lang="scss">
@import "/src/styles/index";
</style>
