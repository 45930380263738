module.exports = {
  // 开发环境
  dev: {
    // base: 'https://tapibase.zhangyujiaqi.com',
    // buyer: 'https://tapibuyer.zhangyujiaqi.com',
    // seller: 'https://tapiseller.zhangyujiaqi.com',
    // admin: 'https://tapiadmin.zhangyujiaqi.com',
    // base: 'http://192.168.2.63:7000',
    // seller: 'http://192.168.2.21:7003',
    // buyer: 'http://192.168.2.63:7002',
    // admin: 'http://192.168.2.63:7004'
    base: 'https://testapibase.bzoe.com.cn',
    seller: 'https://testapiseller.bzoe.com.cn',
    admin: 'https://testapiadmin.bzoe.com.cn'
  },
  // 生产环境
  pro: {
    base: 'https://apibase.zhangyujiaqi.com',
    buyer: 'https://apibuyer.zhangyujiaqi.com',
    seller: 'https://apiseller.zhangyujiaqi.com',
    admin: 'https://apiadmin.zhangyujiaqi.com'
  }
};
