<template>
  <div class="table-layout-container" v-loading="loading">
    <!-- 搜索条件 -->
    <div class="tips-info" v-if="tips">
      <slot name="tips"></slot>
    </div>
    <!-- 按钮 -->
    <div v-if="toolbar" class="toolbar">
      <slot name="toolbar"></slot>
    </div>
    <el-table
      v-bind="$attrs"
      v-on="$listeners"
      ref="table"
      :data="tableData"
      :row-key="rowKey"
      :stripe="stripe"
      :header-cell-style="{textAlign: 'center'}"
      :style="{width: '100%'}"
      :class="!toolbar?'radius':'no-radius'"
      @selection-change="selectionChange"
      highlight-current-row
    >
      <slot name="table-columns"></slot>
    </el-table>
    <div v-if="pagination" class="pagination">
      <div class="pagination-toolbar">
        <slot name="pagination-toolbar"></slot>
      </div>
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnTableLayout",
  props: {
    /** 是否显示斑纹 */
    stripe: {
      type: Boolean,
      default: true,
    },
    /** 行数据的 Key */
    rowKey: {
      type: [String, Function],
      default: null,
    },
    /** 是否显示工具栏 */
    toolbar: {
      type: Boolean,
      default: true,
    },
    /** 是否显示操作提示 */
    tips: {
      type: Boolean,
      default: false,
    },
    /** 是否显示分页 */
    pagination: {
      type: Boolean,
      default: true,
    },
    /** 表格数据 */
    tableData: {
      default: () => [],
    },
    /** 加载状态 */
    loading: {
      type: Boolean,
      default: false,
    },
    /** 当选择项发生变化 */
    selectionChange: {
      type: Function,
      default: () => {
      },
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.table-layout-container {
  width: 100%;
  height: 100%;
  position: relative;
  // background: #e9eef3;
  background: white;
  padding: 1% 2% 0 2%;
}

/** 工具栏样式 */
.toolbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6ebf5;
  background-color: #fff;
  /* margin-bottom: 10px; */
  padding: 16px;
  border-radius: 12px 12px 0 0;
  width: 100%;
}

.inner-toolbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.toolbar-search {
  margin-right: 10px;
}

/** 分页样式 */
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  text-align: right;
  background-color: #ffffff;
  border-radius: 0 0 12px 12px;

  .pagination-toolbar {
    display: flex;
    align-items: center;
  }
}

.no-radius {
  border-radius: 0;
}

.radius {
  border-radius: 12px 12px 0 0;
}

/deep/ .el-table td:not(.is-left) {
  text-align: center;
}

/deep/ .el-table .el-table__header-wrapper thead tr th {
  height: 48px !important;
  font-size: 14px;
  background: rgba(246, 246, 246, 1) !important;
}

/** 操作提示样式 */
.tips-info {
  display: flex;
  align-items: center;
  background: #fff;
  color: #31708f;
  padding: 16px 35px;
  margin-bottom: 24px;
  margin-top: 10px;
  border-radius: 12px;

  h4 {
    font-weight: 500;
    margin: 20px 0;
  }

  ul {
    margin: 20px 0;
    padding-left: 40px;
    display: block;

    li {
      font-size: 12px;
      list-style-type: disc;
    }
  }

  .conditions {
    display: flex;
    align-items: center;
  }
}
</style>
