/**
 * 店铺站内消息相关API
 */

import request from '@/utils/request'

/**
 * 查询店铺站内消息列表
 * @param params
 */
export function shopShopNoticeLogs(params) {
  return request({
    url: '/seller/shops/shop-notice-logs',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 删除店铺站内消息
 * @param ids
 */
export function shopShopNoticeLogsDel(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}`,
    method: 'delete',
    loading: false
  })
}

/**
 * 查询店铺站内消息列表
 * @param ids
 */
export function shopShopNoticeLogsRead(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}/read`,
    method: 'put',
    loading: false
  })
}

export function shopShopNoticeWeidu() {
  return request({
    url: 'seller/shops/shop-notice-logs/count',
    method: 'get',
    loading: false
  })
}
