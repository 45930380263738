/**
 * 首页相关API
 */

import request from '@/utils/request'

/**
 * 订单统计左侧数据
 * @param params
 */
export function dashboardOrderLeft(params) {
  return request({
    url: '/seller/statistics/dashboard/order_left',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 订单统计右侧数据
 * @param params
 */
export function dashboardOrderRight(params) {
  return request({
    url: '/seller/statistics/dashboard/order_right',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款订单统计左侧数据
 * @param params
 */
export function dashboardRefundLeft(params) {
  return request({
    url: '/seller/statistics/dashboard/refund_left',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款订单统计右侧数据
 * @param params
 */
export function dashboardRefundRight(params) {
  return request({
    url: '/seller/statistics/dashboard/refund_right',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 首页数据
 * @param params
 */
export function getHome_date(params) {
  return request({
    url: '/seller/statistics/dashboard/home_date',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 判断信息是否完善
 */
export function shopPrefect() {
  return request({
    url: 'seller/shops/shopInfoFull',
    method: 'get'
  })
}
