/**
 * Created by Andste on 2018/5/9.
 */

import Vue from 'vue'
import {Foundation} from '@/libs/common/utils'
import {api, domain} from '@/libs/common/domain'
import * as XLSX from 'xlsx'

export default {
  data() {
    return {
      // 图片上传API
      MixinUploadApi: api.base + '/uploaders',
      // 地区API
      MixinRegionApi: api.base + '/regions/@id/children',
      // 买家端域名
      MixinBuyerDomain: domain.buyer_pc,
      // 买家端WAP域名
      MixinBuyerWapDomain: domain.buyer_wap,
      // 日期选择器快捷选项
      MixinPickerShortcuts: [{
        text: '最近一周',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近一个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      }, {
        text: '最近三个月',
        onClick(picker) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }]
    }
  },
  computed: {
    /**
     * 缓存页面数组
     * @returns {default.computed.cachedViews|(function())|Array|*|getters.cachedViews}
     */
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    /**
     * 返回默认时间 + 5分钟
     * 用于日期时间选择器的默认时间
     * @returns {string}
     * @constructor
     */
    MixinDefaultTime() {
      const today = new Date()
      let hours = today.getHours()
      let minutes = today.getMinutes() + 5
      let seconds = today.getSeconds()
      if (hours < 10) hours = '0' + hours
      if (minutes < 10) minutes = '0' + minutes
      if (seconds < 10) seconds = '0' + seconds
      return hours + ':' + minutes + ':' + seconds
    }
  },
  methods: {
    getOrderStatusText(status) {
      switch (status) {
        case 'CONFIRM':
          return '待付款';
        case 'PAID_OFF':
          return '待参加';
        case 'IN':
          return '进行中';
        case 'COMPLETE':
          return '已完成';
        case 'REFUNDING':
          return '待退款';
        case 'REFUNDED':
          return '已退款';
        case 'CANCELLED':
          return '已取消';
      }
    },
    getPaymentStatusText(status) {
      switch (status) {
        case 'PAY_NO':
          return '未支付';
        case 'PAY_YES':
          return '已支付';
      }
    },
    /** 返回克隆后的对象 */
    MixinClone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    /** 用于修改单条表格数据后，set到tableData中 */
    MixinSetTableData(tableData, idName, id, response) {
      const {data} = tableData
      const index = data.findIndex(item => item[idName] === id)
      Vue.set(data, index, response)
    },
    /** 用于判断表单是否为空 */
    MixinRequired(message, trigger) {
      return {required: true, pattern: /^\S.*$/gi, message: message, trigger: trigger || 'blur'}
    },
    /** 格式化金钱 */
    MixinFormatPrice(row, column, cellValue, index) {
      if (typeof cellValue !== 'number') return ''
      return '￥' + Foundation.formatPrice(cellValue)
    },
    /** 格式化时间戳 */
    MixinUnixToDate(row, column, cellValue, index) {
      return Foundation.unixToDate(cellValue)
    },
    /** 客户名称格式化 */
    unescape: str => unescape(str),
    /**
     * 导出Excel
     * @param json   要导出的json数据
     * @param name   要导出的文件名
     * @param type   要导出的数据类型
     * @constructor
     */
    MixinExportJosnToExcel(json, name = 'data', type = 'application/octet-stream') {
      const wb = {SheetNames: [], Sheets: {}, Props: {}}
      if (!Array.isArray(json)) json = [json]
      json.forEach(item => {
        wb.SheetNames.push(item.sheet_name)
        wb.Sheets[item.sheet_name] = XLSX.utils.json_to_sheet(item.sheet_values, item.sheet_options)
      })
      const wopts = {bookType: 'xlsx', bookSST: false, type: 'binary'}
      const blob = new Blob([s2ab(XLSX.write(wb, wopts))], {type})
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.style.display = 'none'
      link.href = window.URL.createObjectURL(blob)
      link.download = `${name}.xlsx`
      link.click()
      // 释放资源
      setTimeout(() => {
        URL.revokeObjectURL(link.href)
      }, 100)

      function s2ab(s) {
        if (typeof ArrayBuffer !== 'undefined') {
          const buf = new ArrayBuffer(s.length)
          const view = new Uint8Array(buf)
          for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
          return buf
        } else {
          const buf = new Array(s.length)
          for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF
          return buf
        }
      }
    },
    // 输入框禁止输入表情符号
    MixinEmojiCharacter(substring) {
      for (var i = 0; i < substring.length; i++) {
        var hs = substring.charCodeAt(i)
        if (hs >= 0xd800 && hs <= 0xdbff) {
          if (substring.length > 1) {
            var ls = substring.charCodeAt(i + 1)
            var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000
            if (uc >= 0x1d000 && uc <= 0x1f77f) {
              return true
            }
          }
        } else if (substring.length > 1) {
          var lss = substring.charCodeAt(i + 1)
          if (lss === 0x20e3) {
            return true
          }
        } else {
          if (hs >= 0x2100 && hs <= 0x27ff) {
            return true
          } else if (hs >= 0x2B05 && hs <= 0x2b07) {
            return true
          } else if (hs >= 0x2934 && hs <= 0x2935) {
            return true
          } else if (hs >= 0x3297 && hs <= 0x3299) {
            return true
          } else if (hs === 0xa9 || hs === 0xae || hs === 0x303d || hs === 0x3030 || hs === 0x2b55 || hs === 0x2b1c || hs === 0x2b1b || hs === 0x2b50) {
            return true
          }
        }
      }
    }
  }
}
