<template>
  <div class="nav-bar-menus" @click="$emit('click', $event)">
    <template v-if="type === 'root'">
      <el-menu mode="horizontal">
        <el-menu-item index="2" v-if="!hasActions">
          <template slot="title">
            <slot></slot>
          </template>
        </el-menu-item>
        <el-submenu index="2" v-if="hasActions">
          <template slot="title">
            <slot></slot>
          </template>

          <template v-for="(_action, index) of actions">
            <nav-bar-menu
              :key="index"
              :action="_action"
              :type="getType(_action)"
              :z-index="zIndex + '-' + index"
            />
          </template>
        </el-submenu>
      </el-menu>
    </template>
    <template v-if="type === 'submenu'">
      <el-submenu :index="zIndex">
        <template slot="title">
          <i :class="action.icon"></i>
          <span>{{ action.title }}</span>
        </template>

        <template v-for="(_action, index) of action.children">
          <nav-bar-menu
            :key="index"
            :action="_action"
            :type="getType(_action)"
            :z-index="zIndex + '-' + index"
          />
        </template>
      </el-submenu>
    </template>
    <template v-if="type === 'item'">
      <el-menu-item :index="zIndex" @click="action.handler && action.handler()">
        <i :class="action.icon"></i>
        <span slot="title">{{ action.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script lang="js">
import { Component, Prop, Vue } from 'vue-property-decorator';

export default @Component({
  name: 'NavBarMenu'
})
class NavBarMenu extends Vue {
  @Prop() actions;
  @Prop() action;
  @Prop({ default: 'root' }) type;
  @Prop({ default: '0' }) zIndex;

  get hasActions() {
    return this.actions && this.actions.length > 0;
  }

  getType(action) {
    return action.children && action.children.length > 0 ? 'submenu' : 'item';
  }
}
</script>

<style lang="scss">
.nav-bar-menus {
  .el-menu-item, .el-menu {
    border: none !important;
  }

  .el-menu-item {
    padding: 0;
  }

  .el-submenu__title {
    padding: 0;
    border: none !important;

    .el-submenu__icon-arrow {
      display: none;
    }
  }
}
</style>
