import {Subject} from 'rxjs';
import Storage from '@/utils/storage'
import {$project} from '@/libs/project/project';
import {secrecyMobile} from "@/libs/common/utils/Foundation";
import {$$router} from "@/libs/router/router";
import * as API_platformNotification from "@/api/platformNotification";

export class LayoutNavBarUser {
  nickname = '';
  avatar = '';
  onClick = new Subject();

  constructor() {
    this.load();
    document.addEventListener("webkitvisibilitychange", () => this.reload(), false);
  }

  load() {
    const user = Storage.getItem('seller_user');
    if (user) {
      this.nickname = `${user.nickname} ${secrecyMobile(user.mobile)}`;
      this.avatar = user.face;
    }
  }

  reload() {
    const user = Storage.getItem('seller_user');
    if (user.face !== this.avatar) location.reload();
  }
}

export class LayoutNavBarService {
  appName = '百泽纳商户管理系统';
  appNameEn = 'Seller management system';
  logo = '';
  user = new LayoutNavBarUser();
  profileActions = [];
  leftActions = [];
  rightActions = [];
  onLogoClick = new Subject();

  constructor() {
    this.rightActions.push({
      icon: 'el-icon-message-solid',
      // title: '消息',
      badge: 1,
      handler: () => {
        const router = $$router.get();
        router.forward('/shop/platform-notification');
      },
    });

    this.rightActions.push({
      icon: 'el-icon-question',
      // title: '帮助',
      badge: 0,
      handler: () => {
        const router = $$router.get();
        router.forward('/shop/platform-instructions?type=1');
      },
    });

    this.rightActions.push({
      icon: 'el-icon-switch-button',
      // title: '退出登录',
      badge: 0,
      handler: () => {
        const app = $project.getApp();
        app.$store.dispatch('logoutAction').then(() => {
          app.$router.replace({name: '登录'});
        });
      },
    });

    API_platformNotification.shopShopNoticeWeidu().then(res => {
      this.rightActions[0].badge = res
    })
  }
}

export const $navbar = new LayoutNavBarService()
