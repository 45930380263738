import { createRouterProxy } from '@/libs/router/router';
// start_import
import Layout from '@/libs/layout/layout.vue'
import RouterOutlet from '@/libs/layout/router-outlet/router-outlet.vue'
// end_import

// start_static_routes
const routes = [
  {
    name: '根页面',
    path: '',
    component: Layout,
    children: [
      {
        name: '首页',
        path: '/home',
        meta: {
          name: '首页',
          icon: 'el-icon-house',
          isAuthRequired: true,
          isFirst: true
        },
        component: () => import('./home/home.vue')
      }
    ],
    redirect: '/home'
  },
  {
    name: '状态模块',
    path: '/status',
    meta: {
      name: '状态模块',
      isStatic: true,
      isVirtual: true
    },
    component: RouterOutlet,
    children: [
      {
        name: '错误页404',
        path: 'error-404',
        meta: {
          name: '错误页404',
          isDisableCached: true
        },
        component: () => import('./$status/error-404/error-404.vue')
      },
      {
        name: '错误页401',
        path: 'error-401',
        meta: {
          name: '错误页401',
          isDisableCached: true
        },
        component: () => import('./$status/error-401/error-401.vue')
      }
    ]
  },
  {
    name: '授权模块',
    path: '/auth',
    meta: {
      name: '授权模块',
      isStatic: true,
      isVirtual: true
    },
    component: RouterOutlet,
    children: [
      {
        name: '登录',
        path: 'login',
        meta: {
          name: '登录',
          isDisableCached: true
        },
        component: () => import('./$auth/login/login.vue')
      },
      {
        name: '修改密码',
        path: 'reset-password',
        meta: {
          name: '修改密码',
          isDisableCached: true
        },
        component: () => import('./$auth/reset-password/reset-password.vue')
      }
    ]
  }
];
// end_static_routes

// start_dynamic_routes
const dynamicRoutes = [
  {
    name: '活动管理',
    path: '/activity',
    meta: {
      name: '活动管理',
      icon: 'el-icon-magic-stick',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '活动列表',
        path: 'activity-list',
        meta: {
          name: '活动列表',
          isFirst: true
        },
        component: () => import('./activity/activity-list/activity-list.vue')
      },
      {
        name: '活动列表@',
        path: 'activity-list/:type',
        meta: {
          name: '活动列表@',
          isVirtual: true,
          queryKeys: [
            'type'
          ]
        },
        component: () => import('./activity/activity-list/activity-list.vue')
      },
      {
        name: '活动详情',
        path: 'activity-detail/:id/:type',
        meta: {
          name: '活动详情',
          isVirtual: true,
          bornIn: '活动列表',
          queryKeys: [
            'id',
            'type'
          ]
        },
        component: () => import('./activity/activity-detail/activity-detail.vue')
      },
      {
        name: '查看活动详情',
        path: 'activityShow-detail/:id',
        meta: {
          name: '活动详情',
          isVirtual: true,
          bornIn: '活动列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./activity/activity-detail/activityShow-detail.vue')
      },
      {
        name: '活动详情预览',
        path: 'activityPrview',
        meta: {
          name: '活动详情',
          isVirtual: true,
          bornIn: '活动列表'
        },
        component: () => import('./activity/activity-detail/activityPrview.vue')
      },
      {
        name: '活动草稿列表',
        path: 'activity-draft-list',
        meta: {
          name: '活动草稿列表'
        },
        component: () => import('./activity/activity-draft-list/activity-draft-list.vue')
      },
      {
        name: '活动标签',
        path: 'activity-tag-list',
        meta: {
          name: '活动标签'
        },
        component: () => import('./activity/activity-tag-list/activity-tag-list.vue')
      },
      {
        name: '活动地址',
        path: 'activity-address-list',
        meta: {
          name: '活动地址'
        },
        component: () => import('./activity/activity-address-list/activity-address-list.vue')
      }
    ],
    redirect: '/activity/activity-list'
  },
  {
    name: '订单管理',
    path: '/order',
    meta: {
      name: '订单管理',
      icon: 'el-icon-shopping-cart-1',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '订单列表',
        path: 'order-list',
        meta: {
          name: '订单列表',
          isFirst: true
        },
        component: () => import('./order/order-list/order-list.vue')
      },
      {
        name: '订单列表@',
        path: 'order-list/:type',
        meta: {
          name: '订单列表@',
          isVirtual: true,
          queryKeys: [
            'type'
          ]
        },
        component: () => import('./order/order-list/order-list.vue')
      },
      {
        name: '订单详情',
        path: 'order-detail/:id',
        meta: {
          name: '订单详情',
          isVirtual: true,
          bornIn: '订单列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./order/order-detail/order-detail.vue')
      },
      {
        name: '活动参与人详情',
        path: 'activity-participant-detail/:id',
        meta: {
          name: '活动参与人详情',
          isVirtual: true,
          bornIn: '订单列表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./order/activity-participant-detail/activity-participant-detail.vue')
      }
    ],
    redirect: '/order/order-list'
  },
  {
    name: '账户管理',
    path: '/account',
    meta: {
      name: '账户管理',
      icon: 'el-icon-wallet',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '账户概况',
        path: 'account-profile',
        meta: {
          name: '账户概况',
          isFirst: true
        },
        component: () => import('./account/account-profile/account-profile.vue')
      },
      {
        name: '结算明细表',
        path: 'transaction-list',
        meta: {
          name: '结算明细表'
        },
        component: () => import('./account/transaction-list/transaction-list.vue')
      },
      {
        name: '交易流水详情',
        path: 'transaction-detail/:id',
        meta: {
          name: '交易流水详情',
          isVirtual: true,
          bornIn: '结算明细表',
          queryKeys: [
            'id'
          ]
        },
        component: () => import('./account/transaction-detail/transaction-detail.vue')
      },
      {
        name: '交易退款明细',
        path: 'transaction-refund-list',
        meta: {
          name: '交易退款明细',
          isVirtual: true
        },
        component: () => import('./account/transaction-refund-list/transaction-refund-list.vue')
      },
      {
        name: '账户与提现',
        path: 'platform-revenue',
        meta: {
          name: '账户与提现'
        },
        component: () => import('./account/platform-revenue/platform-revenue.vue')
      },
      {
        name: '退款明细',
        path: 'account-refund-list',
        meta: {
          name: '退款明细'
        },
        component: () => import('./account/account-refund-list/account-refund-list.vue')
      }
    ],
    redirect: '/account/account-profile'
  },
  {
    name: '运营管理',
    path: '/ops',
    meta: {
      name: '运营管理',
      icon: 'el-icon-data-line',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '运营报表',
        path: 'ops-report',
        meta: {
          name: '运营报表',
          isFirst: true
        },
        component: () => import('./ops/ops-report/ops-report.vue')
      },
      {
        name: '优惠券管理',
        path: 'coupon-list',
        meta: {
          name: '优惠券管理'
        },
        component: () => import('./ops/coupon-list/coupon-list.vue')
      },
      {
        name: '优惠券明细',
        path: 'coupon-details',
        meta: {
          name: '优惠券明细',
          isVirtual: true
        },
        component: () => import('./ops/coupon-details/coupon-details.vue')
      },
      {
        name: '客户分析',
        path: 'customer-analysis',
        meta: {
          name: '客户分析'
        },
        component: () => import('./ops/customer-analysis/customer-analysis.vue')
      },
      {
        name: '通知管理',
        path: 'notification-list',
        meta: {
          name: '通知管理'
        },
        component: () => import('./ops/notification-list/notification-list.vue')
      },
      {
        name: '客户列表',
        path: 'customer-list',
        meta: {
          name: '客户列表',
          bornIn: '客户分析',
          isVirtual: true,
        },
        component: () => import('./ops/customer-analysis/customer-list.vue')
      }
    ],
    redirect: '/ops/ops-report'
  },
  {
    name: '店铺管理',
    path: '/shop',
    meta: {
      name: '店铺管理',
      icon: 'el-icon-suitcase',
      isAuthRequired: true
    },
    component: Layout,
    children: [
      {
        name: '店铺基本信息',
        path: 'shop-base-info',
        meta: {
          name: '店铺基本信息',
          isFirst: true
        },
        component: () => import('./shop/shop-base-info/shop-base-info.vue')
      },
      {
        name: '店铺设置',
        path: 'shop-settings',
        meta: {
          name: '店铺设置'
        },
        component: () => import('./shop/shop-settings/shop-settings.vue')
      },
      {
        name: '店铺活动管理',
        path: 'shop-activities-list',
        meta: {
          name: '店铺活动管理'
        },
        component: () => import('./shop/shop-activities-list/shop-activities-list.vue')
      },
      {
        name: '平台通知',
        path: 'platform-notification',
        meta: {
          name: '平台通知'
        },
        component: () => import('./shop/platform-notification/platform-notification.vue')
      },
      {
        name: '说明与须知',
        path: 'platform-instructions',
        meta: {
          name: '说明与须知'
        },
        component: () => import('./shop/platform-instructions/platform-instructions.vue')
      },
      {
        name: '店铺安全',
        path: 'shop-account-safety',
        meta: {
          name: '店铺安全'
        },
        component: () => import('./shop/shop-account-safety/shop-account-safety.vue')
      }
    ],
    redirect: '/shop/shop-base-info'
  }
];
// end_dynamic_routes

// start_routes
const routesMap = {
  ['首页']: '/home',
  ['错误页404']: '/status/error-404',
  ['错误页401']: '/status/error-401',
  ['登录']: '/auth/login',
  ['修改密码']: '/auth/reset-password',
  ['活动列表']: '/activity/activity-list',
  ['活动列表@']: '/activity/activity-list/:type',
  ['活动详情']: '/activity/activity-detail/:id/:type',
  ['活动草稿列表']: '/activity/activity-draft-list',
  ['活动标签']: '/activity/activity-tag-list',
  ['活动地址']: '/activity/activity-address-list',
  ['订单列表']: '/order/order-list',
  ['订单列表@']: '/order/order-list/:type',
  ['订单详情']: '/order/order-detail/:id',
  ['活动参与人详情']: '/order/activity-participant-detail/:id',
  ['账户概况']: '/account/account-profile',
  ['结算明细表']: '/account/transaction-list',
  ['交易流水详情']: '/account/transaction-detail/:id',
  ['交易退款明细']: '/account/transaction-refund-list',
  ['账户与提现']: '/account/platform-revenue',
  ['退款明细']: '/account/account-refund-list',
  ['运营报表']: '/ops/ops-report',
  ['优惠券管理']: '/ops/coupon-list',
  ['优惠券明细']: '/ops/coupon-details',
  ['客户分析']: '/ops/customer-analysis',
  ['通知管理']: '/ops/notification-list',
  ['店铺基本信息']: '/shop/shop-base-info',
  ['店铺设置']: '/shop/shop-settings',
  ['店铺活动管理']: '/shop/shop-activities-list',
  ['平台通知']: '/shop/platform-notification',
  ['说明与须知']: '/shop/platform-instructions',
  ['店铺安全']: '/shop/shop-account-safety'
};
// end_routes

export const $routerSeller = createRouterProxy({
  mode: 'hash',
  routes,
  routesMap,
  dynamicRoutes,
});
