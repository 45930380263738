import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css' // Progress 进度条样式
import {Message} from 'element-ui'
import store from './store'
import Storage from '@/utils/storage'
import {$routerSeller} from './pages/routes'

const whiteList = ['/auth/login', '/auth/reset-password'];

$routerSeller.ctx.beforeEach((to, from, next) => {
  NProgress.start()
  const refreshToken = Storage.getItem('seller_refresh_token')
  if (refreshToken) {
    if (to.path.replace('#', '').startsWith('/auth')) {
      next({
        path: '/',
        replace: true
      });
    } else {
      if (store.getters.addRouters.length === 0) {
        store.dispatch('GenerateRoutes').then(() => {
          $routerSeller.ctx.addRoutes(store.getters.addRouters)
          setTimeout(() => {
            next({
              path: to.fullPath,
              replace: true
            });
          }, 500);
        }).catch(() => {
          store.dispatch('fedLogoutAction').then(() => {
            Message.error('验证失败,请重新登录')
            next({
              name: '登录'
            });
          });
        });
      } else {
        next()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next({
        name: '登录'
      });
    }
  }
})

$routerSeller.ctx.afterEach(() => {
  NProgress.done()
})
